<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                :label="$t('labels.created_date')"
                :placeholder="$t('labels.created_date')"
                name="created_at"
                sort-name="created_at"
                has-sort
                :sorting="filters.sort_by"
                :default-value="filters.created_at"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 120px">
              <SelectEmployee
                :label="$t('labels.employee_create')"
                :placeholder="$t('labels.employee_create')"
                name="id_identity"
                sort-name="identity_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Tên sản phẩm"
                placeholder="Tên sản phẩm"
                name="product_name"
                sort-name="product_name"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Mã sản phẩm"
                placeholder="Mã sản phẩm"
                name="product_code"
                sort-name="product_code"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Cấp ngăn cháy"
                placeholder="Cấp ngăn cháy"
                name="fire_prevention_level"
                sort-name="fire_prevention_level"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" style="width: 120px">
              <InputFilter
                label="Kích thước"
                placeholder="Kích thước"
                name="product_size"
                sort-name="product_size"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Số giấy chứng nhận"
                placeholder="Số giấy chứng nhận"
                name="certificate_number"
                sort-name="certificate_number"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Ngày cấp"
                placeholder="Ngày cấp"
                name="certificate_date"
                sort-name="certificate_date"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Đơn vị cấp"
                placeholder="Đơn vị cấp"
                name="certificate_company"
                sort-name="certificate_company"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Dự án"
                placeholder="Dự án"
                name="project"
                sort-name="project"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Địa chỉ"
                placeholder="Địa chỉ"
                name="address"
                sort-name="address"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilterFromTo
                label="Số tem cấp"
                placeholder="Số tem cấp"
                name="stamp_number"
                sort-name="stamp_number"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                label="Ngày sản xuất"
                placeholder="Ngày sản xuất"
                name="manufacture_date"
                sort-name="manufacture_date"
                has-sort
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in items"
            :key="`ns_${key}`"
            class="text-center"
          >
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ item.identity_name }}</td>
            <td>{{ item.product_name }}</td>
            <td>{{ item.product_code }}</td>
            <td>{{ item.fire_prevention_level }}</td>
            <td>{{ item.product_size }}</td>
            <td>{{ item.certificate_number }}</td>
            <td>{{ item.certificate_date }}</td>
            <td>{{ item.certificate_company }}</td>
            <td>{{ item.project }}</td>
            <td>{{ item.address }}</td>
            <td>
              {{ formatNumber(item.stamp_number) }} <br />
              <small class="error--text"
                >{{ item.stamp_from }} - {{ item.stamp_to }}</small
              >
            </td>
            <td>{{ item.manufacture_date }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" md="4">
        <div class="d-flex align-center">
          <div>
            <div class="d-flex table-footer-count">
              <div class="pt-3">
                {{ $t("labels.sum") }}:
                <b>{{ formatNumber(total) }}</b>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="4"
        ></v-pagination>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-btn
          color="purple"
          class="mr-2"
          outlined
          @click="showGenerateStampDialog"
        >
          <v-icon>mdi-printer</v-icon>
          {{ $t("labels.create_stamp") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="generateStampDialog" persistent max-width="100vw">
      <GenerateStamp
        v-if="generateStampDialog"
        @close="hideGenerateStampDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from "lodash/function";
import { httpClient } from "@/libs/http";
import moment from "moment/moment";
const DEFAULT_DATES = [
  moment().subtract("30", "days").format("YYYY-MM-DD"),
  moment().format("YYYY-MM-DD"),
];

export default {
  name: "Stamp",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    InputFilterFromTo: () => import("@/components/table/InputFilterFromTo"),
    SelectEmployee: () => import("@/components/table/SelectEmployee"),
    GenerateStamp: () => import("@/components/nsca/GenerateStamp"),
  },
  data: () => ({
    isLoading: false,
    generateStampDialog: false,
    items: [],
    page: 1,
    total: 0,
    totalPage: 1,
    filters: {
      created_at: [...DEFAULT_DATES],
    },
  }),
  computed: {},
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList: debounce(function () {
      httpClient
        .post("/nsca-stamp-list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.total = data.total;
          this.items = [...data.rows];
        });
    }, 1000),

    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },

    showGenerateStampDialog() {
      this.generateStampDialog = true;
    },
    hideGenerateStampDialog() {
      this.generateStampDialog = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
